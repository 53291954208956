var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom-end",
        width: "250",
        trigger: "click",
        "popper-class": "table-title",
        "visible-arrow": false,
      },
      on: { show: _vm.getTitles },
    },
    [
      _c("div", { staticClass: "table-title-container" }, [
        _c(
          "ul",
          { staticClass: "table-title-list" },
          [
            _c("li", { staticClass: "table-title-item header" }, [
              _c("span", { staticClass: "title-name" }, [
                _vm._v("\n                    表头\n                "),
              ]),
              _c("span", { staticClass: "title-operation" }, [
                _c("span", { staticClass: "header-tip" }, [_vm._v("上移")]),
                _c("span", { staticClass: "header-tip" }, [_vm._v("下移")]),
                _c("span", { staticClass: "header-tip" }),
              ]),
            ]),
            _vm._l(_vm.visibleTitle, function (item, index) {
              return _c(
                "li",
                { key: item.value, staticClass: "table-title-item" },
                [
                  _c("span", { staticClass: "title-name" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.name) +
                        "\n                "
                    ),
                  ]),
                  !item.disabled
                    ? _c(
                        "span",
                        { staticClass: "title-operation" },
                        [
                          _c("i", {
                            staticClass: "title-operation-icon el-icon-top",
                            class: index > +_vm.startPos ? "" : "hide-icon",
                            on: {
                              click: function ($event) {
                                return _vm.handleTitleTop(index)
                              },
                            },
                          }),
                          _c("i", {
                            staticClass: "title-operation-icon el-icon-bottom",
                            class:
                              (_vm.isFloatingList &&
                                index < _vm.visibleTitle.length - 2) ||
                              (!_vm.isFloatingList &&
                                index < _vm.visibleTitle.length - 1)
                                ? ""
                                : "hide-icon",
                            on: {
                              click: function ($event) {
                                return _vm.handleTitleBottom(index)
                              },
                            },
                          }),
                          item.value !== "lastestStatus"
                            ? [
                                _c(
                                  "span",
                                  {
                                    staticClass: "show",
                                    on: {
                                      click: function ($event) {
                                        return _vm.hideTableTitle(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-icon", {
                                      staticClass: "default",
                                      attrs: { href: "#icon-visible" },
                                    }),
                                    _c("font-icon", {
                                      staticClass: "hover",
                                      attrs: { href: "#icon-hide-hover" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c("span", {
                                  staticClass: "show",
                                  staticStyle: {
                                    width: "18px",
                                    height: "18px",
                                    display: "inline-block",
                                  },
                                }),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              )
            }),
            _vm._l(_vm.hideTitle, function (item, index) {
              return _c(
                "li",
                { key: item.value, staticClass: "table-title-item" },
                [
                  _c("span", { staticClass: "title-name" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.name) +
                        "\n                "
                    ),
                  ]),
                  _c("span", { staticClass: "title-operation" }, [
                    _c(
                      "span",
                      {
                        staticClass: "hide-box",
                        on: {
                          click: function ($event) {
                            return _vm.showTableTitle(index)
                          },
                        },
                      },
                      [
                        _c("font-icon", {
                          staticClass: "default",
                          attrs: { href: "#icon-hide" },
                        }),
                        _c("font-icon", {
                          staticClass: "hover",
                          attrs: { href: "#icon-visible-hover" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-title-confirm" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleTitleOrder },
            },
            [_vm._v("确认修改")]
          ),
        ],
        1
      ),
      _c("i", {
        staticClass: "talent-title-icon icon-font icon-talent_xuanxiang",
        attrs: { slot: "reference" },
        slot: "reference",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }