<template>
    <el-popover
        placement="bottom-end"
        width="250"
        trigger="click"
        popper-class="table-title"
        :visible-arrow="false"
        @show="getTitles"
    >
        <div class="table-title-container">
            <ul class="table-title-list">
                <li class="table-title-item header">
                    <span class="title-name">
                        表头
                    </span>
                    <span class="title-operation">
                        <span class="header-tip">上移</span>
                        <span class="header-tip">下移</span>
                        <span class="header-tip"></span>
                    </span>
                </li>
                <li
                    class="table-title-item"
                    v-for="(item, index) in visibleTitle"
                    :key="item.value"
                >
                    <span class="title-name">
                        {{item.name}}
                    </span>
                    <span class="title-operation" v-if="!item.disabled">
                        <i
                            class="title-operation-icon el-icon-top"
                            :class="index > +startPos ? '' : 'hide-icon'"
                            @click="handleTitleTop(index)"
                        ></i>
                        <i
                            class="title-operation-icon el-icon-bottom"
                            :class="(isFloatingList && index < visibleTitle.length - 2) || (!isFloatingList && index < visibleTitle.length - 1) ? '' : 'hide-icon'"
                            @click="handleTitleBottom(index)"
                        ></i>
                        <template v-if="item.value !== 'lastestStatus'">
                            <span class="show" @click="hideTableTitle(index)">
                                <font-icon class="default" href="#icon-visible"></font-icon>
                                <font-icon class="hover" href="#icon-hide-hover"></font-icon>
                            </span>
                        </template>
                        <template v-else>
                            <span class="show" style="width: 18px;height: 18px;display: inline-block;"></span>
                        </template>
                    </span>
                </li>
                <li
                    class="table-title-item"
                    v-for="(item, index) in hideTitle"
                    :key="item.value"
                >
                    <span class="title-name">
                        {{item.name}}
                    </span>
                    <span class="title-operation">
                        <span class="hide-box" @click="showTableTitle(index)">
                            <font-icon class="default" href="#icon-hide"></font-icon>
                            <font-icon class="hover" href="#icon-visible-hover"></font-icon>
                        </span>
                    </span>
                </li>
            </ul>
        </div>
        <div class="table-title-confirm">
            <el-button type="primary" @click="handleTitleOrder" :loading="confirmLoading">确认修改</el-button>
        </div>
        <i slot="reference" class="talent-title-icon icon-font icon-talent_xuanxiang"></i>
    </el-popover>
</template>

<script>
export default {
    props: {
        titleStore: {
            type: Array,
            default: []
        },
        tableTitle:{
            type: Array,
            default: []
        },
        isFloatingList: false,

        // 第一个能移动表头元素的位置
        startPos: {
            type: String | Number,
            default: 2, // 以前是2，也不知道原因,估计是从第二开始吧
        }
    },
    data() {
        return {
            confirmLoading: false,
            visibleTitle: [],
            hideTitle: []
        }
    },
    methods: {
        getTitles() {
            let visibleList = [],
                hideList = [];
            this.titleStore.forEach(item => {
                let title = {};
                title = this.tableTitle.find((subItem) => {
                    return subItem.value == item;
                });
                if(title.value) {
                    visibleList.push(title);
                }
            });
            this.tableTitle.forEach(item => {
                let index = -1;
                index = this.titleStore.findIndex((subItem) => {
                    return subItem == item.value;
                });
                if(index == -1) {
                    hideList.push(item);
                }
            });
            this.visibleTitle = visibleList;
            this.hideTitle = hideList;
        },
        handleTitleTop(index) {
            let topItem = this.visibleTitle[index];
            this.visibleTitle.splice(index, 1);
            this.visibleTitle.splice(index-1, 0, topItem);
        },
        handleTitleBottom(index) {
            let bottomItem = this.visibleTitle[index];
            this.visibleTitle.splice(index, 1);
            this.visibleTitle.splice(index + 1, 0, bottomItem);
        },
        hideTableTitle(index) {
            let hideItem = this.visibleTitle[index];
            this.visibleTitle.splice(index, 1);
            this.hideTitle.push(hideItem);
        },
        showTableTitle(index) {
            let showItem = this.hideTitle[index];
            this.hideTitle.splice(index, 1);
            if(this.isFloatingList) {
                this.visibleTitle.splice(this.visibleTitle.length-1, 0, showItem);
            } else {
                this.visibleTitle.push(showItem);
            }
        },
        handleTitleOrder() {
            let list = [];
            this.visibleTitle.forEach(item => {
                list.push(item.value);
            });
            this.$emit('table-title', list);
            this.confirmLoading = true;
            setTimeout(() => {
                this.confirmLoading = false;
            }, 1000)
        }
    }
}
</script>

<style lang="scss" scope>
.table-title-container {
    height: 315px;
    overflow: auto;
    .table-title-item {
        display: flex;
        justify-content: space-between;
        height: 32px;
        line-height: 32px;
        padding: 0 10px 0 20px;
        font-size: 14px;
        color: #666;
        &.header {
            border-bottom: 1px solid #eee;
            color: #999;
            .header-tip {
                margin-left: 8px;
                &:nth-of-type(1) {
                    margin-left: 0;
                }
                &:nth-of-type(3) {
                    width: 22px;
                    display: inline-block;
                }
            }
        }
        &:nth-of-type(2n) {
            background-color: rgba(201, 201, 201, 0.1);
        }
        .title-operation-icon {
            font-size: 18px;
            margin-left: 15px;
            &:hover {
                color: $primary;
                cursor: pointer;
            }
            &:nth-of-type(1) {
                margin-left: 0;
            }
            &.hide-icon {
                opacity: 0;
                pointer-events: none;
            }
        }
        .show,
        .hide-box {
            margin-left: 15px;
            cursor: pointer;
            .font-icon {
                width: 18px;
                height: 18px;
            }
            .hover {
                display: none;
            }
            &:hover {
                .default {
                    display: none;
                }
                .hover {
                    display: inline-block;
                }
            }
        }
    }
}
.table-title-confirm {
    text-align: right;
    margin: 10px 18px 0 0;
}
</style>

<style lang="scss">
.talent-title-icon.icon-talent_xuanxiang{
    font-size: 16px;
    color: #999;
    &:focus,&:hover{
        color: $primary;
    }
}
.table-title.el-popover{
    padding: 10px 0;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.30);
    margin-top: 3px;
    .el-checkbox-group{
        color: #666;
        font-size: 14px;
        letter-spacing: 0px;
        .el-checkbox{
            width: 77px;
            margin-right: 20px;
            margin-bottom: 15px;
            .el-checkbox__label{
                padding-left: 5px;
            }
        }
        .el-checkbox__input.is-disabled{
            opacity: 0.5;
        }
        .el-checkbox__input.is-checked + .el-checkbox__label{
            color: #666;
        }
        .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner{
            border-color: $primary;
        }
        .el-checkbox__inner{
            width: 16px;
            height: 16px;
            background-color: #fff;
            border: 1px solid #999;
            &::after{
                width: 4px;
                height: 8px;
                left: 5px;
                border-color: $primary;
            }
        }
    }
}
</style>
